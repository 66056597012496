.desktop-main{
    width: 90%;
    max-width: 1600px;
    margin: 0 auto;
    height: 100%;
    display: flex;
    gap: 40px;
    align-items: stretch;
}
.desktop-left{
    width: 40%;
    color: rgb(145, 145, 145);
    display: flex;
    flex-direction: column;
}
.inscripciones-title{
    font-weight: bold;
    font-size: 1.5rem;
    margin-bottom: 10px;
    height: 46px;
    color: var(--text-primary);
}
.inner-left-box{
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    background-color: rgb(255, 255, 255);
    box-shadow: 0 4px 14px 0 rgb(0, 0, 0, 0.08);
    border-radius: 8px;
    /* padding: 10px; */
    overflow-y: hidden;
    border: 1px solid var(--border-color);
}
.desktop-right{
    width: 60%;
    max-width: 60%;
    min-width: 60%;
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    gap: 12px;
    position: relative;
}
.desktop-inscribed-calendar-tab{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 44px;
    padding: 10px 10px 0 10px;
}

.desktop-left-tab{
    flex-grow: 1;
    background-color: transparent;
    border: none;
    border-bottom: 3px solid var(--text-muted);
    cursor: pointer;
    font-size: 16px;
    color: var(--text-muted);
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    height: 100%;
}


.desktop-inscribed-quantity {
    background-color: var(--accent-color);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 99999px;
    width: 20px;
    height: 20px;
    padding-right: 1px;
    color: white;
    font-size: 14px;
}

.desktop-left-tab-active{
    color: black;
    border-color: black;
    font-weight: bold;
}


.warn-ok{
    color: var(--accent-color);
}
.warn-no-ok{
    color: #FACF72;
}