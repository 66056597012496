.subjects-main-container{
    display: flex;
    gap: 15px;
    flex-direction: column;
    padding: 12px;
    padding-top: 16px;
}

.subject-type-count{
    color: var(--text-muted);
    font-size: 11px;
    border-radius: 5px;
    border: 1px solid var(--text-muted);
    padding: 3px;
    font-weight: normal;
}
.back-to-login-btn{
    color: var(--text-primary);
    font-weight: bold;
}

.mobile-cursos-box{
    background-color: rgb(255, 255, 255);
    box-shadow: 0 4px 14px 0 rgb(0, 0, 0, 0.08);
    border-radius: 8px;
    padding: 10px;
    border: 1px solid var(--border-color);
    min-height: 60vh;
}
.mobile-calendario-box{
  position: relative;
    background-color: rgb(255, 255, 255);
    box-shadow: 0 4px 14px 0 rgb(0, 0, 0, 0.08);
    border-radius: 8px;
    /* padding: 0 10px 10px 10px; */
    border: 1px solid var(--border-color);
    min-height: 80vh;
    overflow-y: hidden;
}
.mobile-theader-cursos{
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--border-color);
  font-size: 14px;
  padding: 5px ;
  color: var(--text-muted);
}